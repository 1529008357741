p.p1 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 11.5px Lato;
  color: #000000;
}

p.p2 {
  margin: 0px 0px 5px 0px;
  text-align: justify;
  font: 18px Cambria;
  color: #000000;
}

p.p3 {
  margin: 0px 0px 5px 0px;
  text-align: justify;
  font: 11.5px Lato;
  color: #000000;
}

p.p6 {
  margin: 0px 0px 0px 36px;
  font: 10.5px Cambria;
  color: #000000;
}

p.p7 {
  margin: 0px 0px 0px 36px;
  font: 11.5px Cambria;
  color: #000000;
  min-height: 14px;
}

p.p8 {
  margin: 0px 0px 1.5px 0px;
  text-align: justify;
  font: 11.5px Lato;
  color: #000000;
}

p.p9 {
  margin: 0px 0px 1.5px 0px;
  text-align: justify;
  font: 11.5px Lato;
  color: #000000;
  min-height: 13px;
}

p.p10 {
  margin: 0px 0px 5px 0px;
  text-align: justify;
  font: 11.5px Lato;
  color: #0000ff;
}

li.li4 {
  margin: 0px 0px 0px 0px;
  font: 11.5px Cambria;
  color: #000000;
}

li.li5 {
  margin: 0px 0px 0px 0px;
  font: 10.5px Cambria;
  color: #000000;
}

span.s1 {
  text-decoration: underline;
  color: #0000ff;
}

span.s2 {
  font: 10px Symbol;
}

span.s4 {
  font: 11.5px Cambria;
}

span.s5 {
  font: 11.5px Cambria;
  color: #0000ff;
}

span.s6 {
  text-decoration: underline;
}

ul.ul1 {
  list-style-type: disc;
}
