// Icon nav
.icon-tab .nav-link {
  border: 1px solid #e9e9e9;
  color: #495057;
}

.icon-tab .active.nav-link {
  background: #556ee6;
  color: #fff;
}

.icon-tab .nav-link i,
.vertical-icon .nav-link i {
  padding-right: 3px;
  font-size: 14px;
}

.vertical-icon .active.nav-link {
  border: 1px solid #556ee6;
  border-radius: 21px;
  background: #556ee6;
  color: #fff;
}

.vertical-icon .nav-link {
  color: #495057;
}

//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $fw-medium;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $fw-medium;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;

    .nav-link {
      border: none;

      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;

        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;

      .nav-icon {
        font-size: 24px;
      }
    }
  }
}

.dropdown-text {
  color: #555b6d !important;
}

.dropdown-text:hover {
  color: #556ee6 !important;
}

.dropdown-icon {
  color: #ffffff99;
}

.dropdown-icon:hover {
  color: #fff;
}

.zoom-animation:hover {
  transition-duration: 300ms;
  font-size: 3rem !important;
}

.text-primary {
  color: #556ee6 !important;
}
